import gql from 'graphql-tag';

import USER_FIELDS from '../fields/user';

export const ACCOUNT = gql`
  query Account($token: String!) {
    account(token: $token) {
      ${USER_FIELDS}
    }
  }
`;

export const USER_DEFAULTS = gql`
  query UserDefaults {
    userDefaults {
      location
    }
  }
`;
