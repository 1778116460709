import React from 'react';

import { ConservativeIcon, AggressiveIcon, DefensiveIcon, BalancedIcon, GrowthIcon } from './iconUtils';

export type TCountry = {
  name: string,
  exchange: string,
  minimumPlan: number,
  currency: string,
}

export type TSector = {
  label: string,
  value: string,
  markets: string[],
}

const CUSTOMER_PLANS = [
  'ANONYMOUS',
  'REGISTERED',
  'BASIC',
  'PREMIUM',
];

// USER LEVELS
export const ANONYMOUS_USER_INDEX = CUSTOMER_PLANS.findIndex(p => p === 'ANONYMOUS');
export const REGISTERED_USER_INDEX = CUSTOMER_PLANS.findIndex(p => p === 'REGISTERED');
export const PREMIUM_USER_INDEX = CUSTOMER_PLANS.findIndex(p => p === 'PREMIUM');

export const INVESTMENT_STRATEGIES = [{
  name: 'LOW_BETA',
  minimumPlan: PREMIUM_USER_INDEX,
  description: "The strategy aims to identify REITs that have exhibited consistently lower volatility and hence lower risk while still being able to maintain consistent dividends.",
  defaultSort: {
    sortField: 'volatility',
    orderBy: 'DESC',
  },
}, {
  name: 'DIVIDEND_MACHINES',
  minimumPlan: REGISTERED_USER_INDEX,
  description: "This strategy sieves out REITs that provide a balance between income stability and predictability while accepting a managed level of risk. Suitable for income investors who prioritises income stability over gains.",
  defaultSort: {
    sortField: 'dividends',
    orderBy: 'DESC',
  },
}, {
  name: 'YIELD_CHASER',
  minimumPlan: ANONYMOUS_USER_INDEX,
  description: "This strategy selects REITs that maximises dividend income of the portfolio while accepting a heightened risk of market turbulence.",
  defaultSort: {
    sortField: 'dividends',
    orderBy: 'DESC',
  },
}, {
  name: 'VALUE_SEEKER',
  minimumPlan: ANONYMOUS_USER_INDEX,
  description: "This strategy seeks to uncover undervalued REITs where their stock prices are trading significantly below the appraised value of their properties. The idea is to buy in early and wait patiently for the market to discover this treasure.",
  defaultSort: {
    sortField: 'priceToBookRatio',
    orderBy: 'DESC',
  },
}, {
  name: 'VALUE_GROWTH',
  minimumPlan: PREMIUM_USER_INDEX,
  description: "This strategy combines the advantages of investing in a growth REIT while buying only at bargain prices. Such opportunities are rare to come by, but when they do, they could be a real gem.",
  defaultSort: {
    sortField: 'fiveYrDPUGrowthRate',
    orderBy: 'DESC',
  },
}, {
  name: 'GROWTH_ENGINE',
  minimumPlan: PREMIUM_USER_INDEX,
  description: "This strategy aims to sniff out the highest potential growth REITs while being content with a lower overall dividend yield. The stock prices may not be at a bargain, but as long as they are reasonable, the potential upside gains should more than amply reward the investor.",
  defaultSort: {
    sortField: 'threeYrDPUGrowthRate',
    orderBy: 'DESC',
  },
}];

export const INVESTOR_PROFILES = [{
  icon: <ConservativeIcon />,
  name: "CONSERVATIVE",
  description: "The conservative investor places the highest objective on minimising risk and preserving capital. They may need to have access to the capital in the short-term and would need to liquidate at a moment's notice.  They are prepared to accept low or no growth and are satisfied with small but predictable income. The REITs that they own have a strong  track record of low volatility, stable dividends and low debts. They seek out medium to large sized REITs which are easy to buy in or sell out. Conservative investors are in no rush to invest and can afford to wait until the prices of these REITs are at good value or bargain prices.",
}, {
  icon: <DefensiveIcon />,
  name: "DEFENSIVE",
  description: "The defensive investor focuses on the need for stable and predictable income. They usually will be able to tolerate small fluctuations in their capital knowing that their dividends will more than make up for it. Defensive investors know that investing is important and seek to build a stable income stream with reasonable amount of risk with a medium to long term investment horizon. The REITs that they desire tend to have a strong track record of stable and predictable dividends and are often established names with conservative debts.",
}, {
  icon: <BalancedIcon />,
  name: "BALANCED",
  description: "The balanced investor seeks to balance risk against the total returns. Having a medium to long term investment horizon, they know that time in on their side and are comfortable with moderate amounts of market turbulence and seeing short term losses. They may have modest amounts of capital and are willing to accept moderate risk and volatility to growth their nest egg by balancing capital gains and income. The REITs that they desire are a balanced mix of some moderate growth REITs and some with an established track record of stable and predictable dividends.",
}, {
  icon: <GrowthIcon />,
  name: "GROWTH",
  description: "The growth investor have greater risk tolerance and prioritises capital gains and profitability in their investments while willing to accept a lower yield on their dividends. They are usually savvier investors with numerous years of investing and are also willing to spend greater amounts of time to monitor the market and take decisive action. They understand that with the potentially higher gains come potentially higher risk and volatility the the stability of their capital is of a lower priority. The REITs that they desire tend to have a strong track record of high dividend growth and aggressive gearing and leverage.",
}, {
  icon: <AggressiveIcon />,
  name: "AGGRESSIVE",
  description: "The high-growth investors are willing to accept very high volatility and risk in order to maximise their investment returns. They know that the investment journey for them is going to be bumpy one, but they have the experience, foresight and fortitude to stay the course and reap great rewards over the medium and long term. The REITs that they desire tend to be highly leveraged in order to growth quickly.",
}];

export const COUNTRIES: TCountry[] = [{
  name: 'Singapore',
  exchange: 'SGX',
  minimumPlan: ANONYMOUS_USER_INDEX,
  currency: 'S$'
}, {
  name: 'Hong Kong',
  exchange: 'HKEX',
  minimumPlan: PREMIUM_USER_INDEX,
  currency: 'HK$',
}, {
  name: 'Malaysia',
  exchange: 'MYX',
  minimumPlan: PREMIUM_USER_INDEX,
  currency: 'RM',
}, {
  name: 'Australia',
  exchange: 'ASX',
  minimumPlan: PREMIUM_USER_INDEX,
  currency: 'A$',
}, {
  name: 'USA',
  exchange: 'NYSE',
  minimumPlan: PREMIUM_USER_INDEX,
  currency: 'US$'
}];

export const SECTORS: TSector[] = [
  {
    value: "HEALTHCARE",
    markets: ["SGX", "ASX", "NYSE"],
  },
  {
    value: "HOSPITALITY",
    markets: ["SGX", "HKEX", "MYX", "ASX", "NYSE"],
  },
  {
    value: "INDUSTRIAL",
    markets: ["SGX", "MYX", "ASX", "NYSE"],
  },
  {
    value: "RETAIL",
    markets: ["SGX", "HKEX", "MYX", "ASX", "NYSE"],
  },
  {
    value: "COMMERCIAL",
    markets: ["SGX", "HKEX", "MYX", "ASX", "NYSE"],
  },
  {
    value: "DATA_CENTRES",
    markets: ["SGX", "NYSE"],
  },
  {
    value: "SPECIALTY",
    markets: ["ASX", "NYSE"],
  },
  {
    value: "RESIDENTIAL",
    markets: ["NYSE"],
  },
  {
    value: "GAMING",
    markets: ["NYSE"],
  },
  {
    value: "DIVERSIFIED",
    markets: ["NYSE"],
  },
].map((sector) => ({
  ...sector,
  label: sector.value.replace(/_/g, " ").toLowerCase(),
}));

// export const DEFAULT_COUNTRY = COUNTRIES.find(({ exchange }) => exchange === 'SGX')!; // free market
// export const DEFAULT_SECTORS = SECTORS.filter(({ markets }) => markets.includes(DEFAULT_COUNTRY.exchange));

export const FILTER_CATEGORIES: { label: string, value: string, subCategories?: { label: string, value: string }[] }[] = [{
  value: 'BASICS',
}, {
  value: 'KEY_STATISTICS',
}, {
  value: 'PORTFOLIO',
}, {
  value: 'GROWTH',
  subCategories: [{
    value: 'DIVIDEND_GROWTH',
  }, {
    value: 'NAV_GROWTH',
  }]
}, {
  value: 'DEBT',
}].map(({ value, subCategories }) => ({
  label: value.replace(/_/g, ' ').toLowerCase(),
  value,
  ...(subCategories && {
    subCategories: subCategories.map((subCat) => ({
      label: subCat.value.replace(/_/g, ' ').toLowerCase(),
      value: subCat.value,
    })),
  })
}));

export const BASIC_FILTERS = FILTER_CATEGORIES.find(({ value }) => value === 'BASICS')!.value;
export const KEY_STATISTICS_FILTERS = FILTER_CATEGORIES.find(({ value }) => value === 'KEY_STATISTICS')!.value;
export const PORTFOLIO_FILTERS = FILTER_CATEGORIES.find(({ value }) => value === 'PORTFOLIO')!.value;
export const DIVIDEND_GROWTH_FILTERS = FILTER_CATEGORIES
  .find(({ value }) => value === 'GROWTH')!
  .subCategories!.find(({ value }) => value === 'DIVIDEND_GROWTH')!.value;
export const NAV_GROWTH_FILTERS = FILTER_CATEGORIES
  .find(({ value }) => value === 'GROWTH')!
  .subCategories!.find(({ value }) => value === 'NAV_GROWTH')!.value;
export const DEBT_FILTERS = FILTER_CATEGORIES.find(({ value }) => value === 'DEBT')!.value;

export const SUBSCRIPTION_SOURCE = [{
  bundle: false,
  name: 'REITScreener TV',
  plans: [{
    addon: true,
    planKey: 'RTV_MONTHLY',
    type: 'rtvMonthly',
    cycle: 'monthly',
    price: 49,
  }, {
    addon: true,
    planKey: 'RTV_ANNUAL',
    type: 'rtvAnnual',
    cycle: 'yearly',
    price: 468,
  }],
  features: [{
    label: 'Weekly Market Briefs',
    description: 'Access our latest opinion and perspectives on the REIT markets and our coverage of significant market events dominating the headlines.',
  }, {
    label: 'Access our latest opinion and perspectives on the REIT markets and our coverage of significant market events dominating the headlines.',
    description: 'Delve deeper into our thematic and topical coverage ranging from expert interviews, macro-economic trends, financial market analysis, REIT market insights and specific REIT case studies.',
  }, {
    label: 'Recorded Webinars',
    description: 'Access special recordings of our past events. Edited and structured into bite-sized topics that enhances your appreciation of REITs and the markets.'
  }],
  footerText: 'This subscription will be billed yearly/monthly. You may cancel your subscription at any time to end renewal charges.',
  inApp: true,
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  bundle: false,
  name: 'REITScreener Premium',
  plans: [{
    addon: false,
    planKey: 'PREMIUM',
    type: 'premium',
    cycle: 'yearly',
    price: 99,
  }],
  features: [{
    label: 'Access to ALL markets',
    description: 'We cover Singapore, Hong Kong, Malaysia, Australia and US REIT markets, with more coming!',
  }, {
    label: 'Advanced Filters',
    description: 'Over 20 REIT specific filters to choose from. Tailor REITScreener to suite your own personal style of investing.',
  }, {
    label: 'Compare & Watchlist',
    description: 'Compare multiple REITs side-by-side to highlight each criteria and shortlist the gems with a single click.',
  },{
    label: 'Premium Screening Strategies',
    description: 'Save your months of research by using our carefully crafted premium screening strategies. '
  }],
  footerText: 'This subscription will be billed yearly. You may cancel your subscription at any time to end renewal charges.',
  inApp: true,
  minimumPlan: REGISTERED_USER_INDEX,
}, {
  bundle: false,
  name: 'REITScreener Data Boost',
  plans: [{
    addon: true,
    planKey: 'DATA_BOOST',
    type: 'dataBoost',
    cycle: 'yearly',
    price: 249,
  }],
  features: [{
    label: 'Optimise your Buy/Sell Prices',
    description: 'Gain REITScreener advantage with our popular buy/sell price zones guides. Buy/sell zones are colour coded Green and Red so that you will never miss the optimum buy or sell opportunity.',
  }, {
    label: 'Analyse Past Track Records',
    description: 'To know how well a REIT has been managed, look no further than its past track records. Focus on analysis that matters.',
  }, {
    label: 'Observe Trends and Manage Risk',
    description: 'Take care of the downside and the upside will take care of itself. Get into the nitty-gitty to ensure you have got all your bases covered.',
  }],
  footerText: 'This subscription will be billed yearly. You may cancel your subscription at any time to end renewal charges.',
  inApp: true,
  minimumPlan: PREMIUM_USER_INDEX,
}, {
  bundle: false,
  name: 'REITScreener Pro',
  plans: [{
    addon: false,
    planKey: 'PROFESSIONAL',
    type: 'pro',
    cycle: 'yearly',
    price: 799,
  }],
  features: [{
    label: '',
    description: 'Get access to the world’s most powerful REIT data table! You will get access to over 24 metrics per REIT. This collection of data cannot be found anywhere else. Stop filtering REITs based on traditional metrics and level up your knowledge.',
  }],
  inApp: false,
  minimumPlan: PREMIUM_USER_INDEX,
}];
