import React, { useEffect } from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps } from "react-router-dom";

import PageSpinner from "components/PageSpinner";
import ErrorPage from "pages/ErrorPage";
import { isLoggedIn, getAuthToken, setAuthToken } from "utils/userUtils";
import { ACCOUNT, USER_DEFAULTS } from "apollo/queries/user";
import { IAccount } from "apollo/types/user";
import { UserProvider } from "contexts/UserContext";
import { EduSessionProvider } from "contexts/EduSessionContext";
import { COUNTRIES } from "utils/appUtils";
import { EDUCATION_SESS_QUERY } from "apollo/queries/education";
import { IEduSessionData } from "contexts/EduSessionContext";

const redirectToRootPaths = ["/login", "/signup/intro"];

interface IUserDefaultsResult {
  userDefaults: {
    location: string;
  };
}

interface IQueryVariables {
  token: string;
}

interface IQueryData {
  account: IAccount;
}

interface IEduSessionContext {
  sess1: IEduSessionData[];
  sess2: IEduSessionData[];
  sess3: IEduSessionData[];
}

export default <P extends object>(
  ComposedComponent: React.ComponentType<P>,
  requireAuth = true
) => {
  const RequireAuth = (props: RouteComponentProps) => {
    const token = getAuthToken();

    const { data: eduSessionData, loading: eduSessionLoading } = useQuery<
      IEduSessionContext
    >(EDUCATION_SESS_QUERY);
    const {
      data: locationData,
      loading: locationLoading,
      error: locationError,
    } = useQuery<IUserDefaultsResult>(USER_DEFAULTS);
    const { data, loading, error } = useQuery<IQueryData, IQueryVariables>(
      ACCOUNT,
      {
        variables: { token },
        skip: !isLoggedIn(),
      }
    );
    const errorSource = error || locationError;

    useEffect(() => {
      const redirectToHome = () => {
        if (isLoggedIn()) {
          const { history } = props;
          const { pathname } = window.location;
          if (redirectToRootPaths.includes(pathname)) {
            history.replace("/");
          }
        }
      };
      const redirectToLogin = () => {
        if (!isLoggedIn()) {
          const { history } = props;
          history.replace("/login");
        }
      };
      requireAuth ? redirectToLogin() : redirectToHome();
    }, [props]);

    if (errorSource) {
      localStorage.clear();
      setAuthToken("");
      return <ErrorPage errorMessage={errorSource.graphQLErrors[0].message} />;
    }

    return loading || locationLoading || eduSessionLoading ? (
      <PageSpinner />
    ) : (
      <UserProvider
        value={{
          token,
          account: data?.account,
          defaultCountry: COUNTRIES.find(
            ({ exchange }) => exchange === locationData?.userDefaults.location
          ),
        }}
      >
        <EduSessionProvider eduSessions={eduSessionData}>
          <ComposedComponent {...(props as P)} />
        </EduSessionProvider>
      </UserProvider>
    );
  };
  return RequireAuth;
};
