import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TSortOrder = {
  sortField: string,
  orderBy: string,
};

export interface TFilterConfigInitialState {
  strategy: string | null,
  advancedMode: boolean,
  sortOrder: TSortOrder,
}

const filterConfigInitialState: TFilterConfigInitialState = {
  strategy: null,
  advancedMode: false,
  sortOrder: {
    sortField: 'exchange',
    orderBy: 'ASC',
  },
}

const filterConfigSlice = createSlice({
  name: 'filterConfig',
  initialState: filterConfigInitialState,
  reducers: {
    setFilterView: (state, { payload }: PayloadAction<boolean>) => {
      state.advancedMode = payload;
    },
    setStrategy: (state, { payload }: PayloadAction<string>) => {
      state.strategy = payload;
    },
    setSortOrder: (state, { payload }: PayloadAction<TSortOrder>) => {
      state.sortOrder = payload;
    }
  }
});

export const { setStrategy, setFilterView, setSortOrder } = filterConfigSlice.actions;
export default filterConfigSlice.reducer;
