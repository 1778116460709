import gql from "graphql-tag";

export const EDUCATION_SESS_QUERY = gql`
  query eduSessions {
    sess1: educationSessions(session: 1) {
      title
      group
      videos {
        title
        img
        src
        trainingVideoId
        timestamp {
          title
          time
        }
      }
    }
    sess2: educationSessions(session: 2) {
      title
      group
      videos {
        title
        img
        src
        trainingVideoId
        timestamp {
          title
          time
        }
      }
    }
    sess3: educationSessions(session: 3) {
      title
      group
      videos {
        title
        img
        src
        trainingVideoId
        timestamp {
          title
          time
        }
      }
    }
  }
`;
