import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TFilterOption } from "utils/filterUtils";
import { TScreenerFilterContent } from "utils/screenerUtils";
import { TSector, TCountry } from "utils/appUtils";

export type TFilterState = {
  [key: string]: {
    option: TFilterOption[];
    range: {
      from: number; // min
      to: number; // max
    };
  };
};

export type TFixedFilterState = {
  countries: TCountry[];
  sectors: TSector[];
};

export interface TFilterStateInitialState {
  fixedFilterState: TFixedFilterState;
  filterState: TFilterState;
  filters: TScreenerFilterContent[];
}

const filterStateInitialState: TFilterStateInitialState = {
  fixedFilterState: {
    countries: [],
    sectors: [],
  },
  filterState: {},
  filters: [],
};

const filterStateSlice = createSlice({
  name: "filterState",
  initialState: filterStateInitialState,
  reducers: {
    setFixedFilterState: (
      state,
      { payload }: PayloadAction<TFixedFilterState>
    ) => {
      state.fixedFilterState = payload;
    },
    setFilterState: (state, { payload }: PayloadAction<TFilterState>) => {
      state.filterState = payload;
    },
    setFilters: (
      state,
      { payload }: PayloadAction<TScreenerFilterContent[]>
    ) => {
      state.filters = payload;
    },
    clearFilters: (state) => {
      const baseFilters = state.filters.filter((filter) =>
        ["exchange", "sector"].includes(filter.key)
      );
      state.filters = baseFilters;
      const filterList = state.filterState;
      for (let item in filterList) {
        filterList[item].option = [];
        filterList[item].range = { from: 0, to: 0 };
      }
    },
  },
});

export const {
  setFilterState,
  setFilters,
  setFixedFilterState,
  clearFilters,
} = filterStateSlice.actions;
export default filterStateSlice.reducer;
