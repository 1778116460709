import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IPageSpinner {
  loading?: boolean,
  label?: string,
  children?: any,
  style?: { [key: string]: string | number },
}

const StyledPageSpinnerWrapper = styled.div`
  .ant-spin {
    &.page-spinner {
      min-height: 100vh;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-spin-text {
        padding-top: 12px;
      }
    }
  }
`;

const StyledSpinnerWrapper = styled.div`
  justify-content: center;
  align-items: center;
  min-height: 400px;
  .ant-spin-text {
    padding-top: 12px;
  }
`;

export const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

export const Spinner: React.FC<IPageSpinner> = ({ loading = true, label, style = {} }) => (
  <StyledSpinnerWrapper className="d-flex mini-spinner" style={style}>
    <Spin
      indicator={LoadingIcon}
      {...(label && {
        tip: label,
      })}
      size="large"
      spinning={loading}
    />
  </StyledSpinnerWrapper>
);

export default ({ loading = true, label }: IPageSpinner) => (
  <StyledPageSpinnerWrapper>
    <Spin
      className="page-spinner"
      size="large"
      {...(label && {
        tip: label,
      })}
      indicator={LoadingIcon}
      spinning={loading}
    />
  </StyledPageSpinnerWrapper>
);
