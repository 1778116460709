import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import rootReducer from './reducers';
import { isDev } from 'config/env';

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  }),
  logger,  // add more middlewares here
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: isDev, // devTools active only on dev
});

export default store;

// added from redux
if (isDev && module.hot) {
  module.hot.accept('./reducers', () => {
    const newRootReducer = require('./reducers').default;
    store.replaceReducer(newRootReducer);
  });
}
