import { IAccount } from 'apollo/types/user';

export const setAuthToken = (token: string): void => localStorage.setItem('auth_token', token);

export const getAuthToken = (): string => localStorage.getItem('auth_token') || '';

export const setRefreshToken = (refreshToken: string): void => localStorage.setItem('refresh_token', refreshToken);

export const getRefreshToken = (): string => localStorage.getItem('refresh_token') || '';

export const isLoggedIn = (): boolean => Boolean(getAuthToken());

export const getDisplayName = (account: Partial<IAccount> | undefined) => {
  const nameString = account?.firstname && account.lastname ?
    `${account.firstname} ${account.lastname}` : account?.username;
  return nameString;
}