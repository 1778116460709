import React, { PropsWithChildren, Suspense, useEffect } from "react";
import {
  Switch,
  Route,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga";

import routes from "./config/routes";
import FourOhFour from "./pages/FourOhFour";
import { MessageProvider } from "contexts/MessageContext";
import env from "config/env";
import PageSpinner from "components/PageSpinner";

const stripePromise = loadStripe(
  process.env[`REACT_APP_${env}_STRIPE_KEY`] ||
    process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
);
const AppProviders: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <MessageProvider>
    <Elements stripe={stripePromise}>{children}</Elements>
  </MessageProvider>
);

const App: React.FC<RouteComponentProps> = ({ location }) => {
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <AppProviders>
      <Suspense fallback={<PageSpinner />}>
        <Switch>
          {routes.map(({ component: Component, path, exact, ...rest }) => (
            <Route
              exact={exact}
              key={path}
              path={path}
              render={(props: RouteComponentProps & any) => (
                <Component {...props} {...rest} />
              )}
            />
          ))}
          {/* <Redirect exact from="/" to="/dashboard"/> */}
          <Route
            path="/essentialtoolkit"
            render={() => {
              window.location.replace(
                "http://essentialtoolkit.reitscreener.com/"
              );
              return null;
            }}
          />
          <Route
            path="/ridetorecovery"
            render={() => {
              window.location.replace(
                "http://ridetorecovery.reitscreener.com/"
              );
              return null;
            }}
          />
          <Route component={FourOhFour} />
        </Switch>
      </Suspense>
    </AppProviders>
  );
};

export default withRouter(App);
