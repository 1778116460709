import React, { createContext, useContext, useState, useEffect } from 'react';

import { IAccount } from 'apollo/types/user';
import { TCountry, COUNTRIES } from 'utils/appUtils';
import { INVESTMENT_STRATEGIES, PREMIUM_USER_INDEX, ANONYMOUS_USER_INDEX } from 'utils/appUtils';

const DEFAULT_FALLBACK_COUNTRY = COUNTRIES.find(({ exchange }) => exchange === 'SGX')!;

interface IUserContextProps {
  account: IAccount | undefined,
  token: string | null,
  defaultCountry: TCountry | undefined,
}

interface IUserContext extends IUserContextProps {
  defaultCountry: TCountry,
  defaultStrategy: {
    name: string,
    minimumPlan: number,
    description: string,
    defaultSort: {
      sortField: string,
      orderBy: string,
    },
  },
}

interface IUserProvider {
  value: IUserContextProps,
}

const UserContext = createContext<IUserContext>({
  account: undefined,
  token: null,
  defaultCountry: DEFAULT_FALLBACK_COUNTRY,
  defaultStrategy: {
    name: '',
    minimumPlan: ANONYMOUS_USER_INDEX,
    description: '',
    defaultSort: {
      sortField: 'exchange',
      orderBy: 'ASC',
    },
  },
});

const UserProvider: React.FC<IUserProvider> = ({ value, children }) => {
  const { account, token, defaultCountry } = value;
  //sniff on local storage
 
  const derivedCountry = defaultCountry || DEFAULT_FALLBACK_COUNTRY;

  const enabledStrategies = INVESTMENT_STRATEGIES.filter(({ minimumPlan }) => {
    return (account?.level || 0) >= minimumPlan
  });

  const isUserPremium = (account?.level || 0) >= PREMIUM_USER_INDEX;
  const userStrategy = enabledStrategies.find(({ name }) => name === account?.quiz?.strategy);
  const defaultStrategy = isUserPremium ? (userStrategy || enabledStrategies[0]) : enabledStrategies[0];

  const [userState, setUserState] = useState<IUserContext>({
    account: undefined,
    token: null,
    defaultStrategy,
    defaultCountry: derivedCountry,
  });

  useEffect(() => {
    setUserState({
      account, token,
      defaultStrategy,
      defaultCountry: derivedCountry,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, token]);

  return (
    <UserContext.Provider
      value={{
        ...userState,
        defaultCountry: derivedCountry, 
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const UserConsumer = UserContext.Consumer;

const useUserContextValue = () => useContext(UserContext);

export { UserProvider, useUserContextValue, UserConsumer };

export default UserContext;
