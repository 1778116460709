import React, { createContext, useContext, useState, useEffect } from "react";

type TrainingVideo = {
  trainingVideoId: number | undefined;
  trainingVideoOrder: number | undefined;
  title: string | undefined;
  description: string | undefined;
  src: string | undefined;
  img: string | undefined;
  status: "COMPLETED" | "VIEWABLE" | "LOCKED" | undefined;
  time: string;
  timestamp: Array<{ title: string; time: string }>;
};

export interface IEduSessionData {
  group: number | undefined;
  title: string | undefined;
  status: "COMPLETED" | "UNLOCKED" | "LOCKED" | undefined;
  videos: TrainingVideo[];
}

interface IEduSessionContextOrig {
  sess1: IEduSessionData[];
  sess2: IEduSessionData[];
  sess3: IEduSessionData[];
}

interface IEduSessionContext {
  list: {
    [key: string]: IEduSessionData;
  };
  hasVidAccess: boolean;
  hasVidAccessAll: boolean;
}

interface IEduContextProps {
  eduSessions: IEduSessionContextOrig | undefined;
}

let defaultSession: IEduSessionData = {
  group: undefined,
  title: undefined,
  status: undefined,
  videos: [],
};

const EduContext = createContext<{ sessionData: IEduSessionContext }>({
  sessionData: {
    list: {
      sess1: defaultSession,
      sess2: defaultSession,
      sess3: defaultSession,
    },
    hasVidAccess: false,
    hasVidAccessAll: false,
  },
});

const EduSessionProvider: React.FC<IEduContextProps> = ({
  children,
  eduSessions,
}) => {
  const [eduSess, setEduSess] = useState<IEduSessionContext>({
    list: {
      sess1: defaultSession,
      sess2: defaultSession,
      sess3: defaultSession,
    },
    hasVidAccess: false,
    hasVidAccessAll: false,
  });

  // console.log(eduSessions);

  useEffect(() => {
    if (eduSessions) {
      setEduSess({
        list: {
          sess1: eduSessions.sess1[0],
          sess2: eduSessions.sess2[0],
          sess3: eduSessions.sess3[0],
        },
        hasVidAccess:
          eduSessions.sess1[0].videos[0].src ||
          eduSessions.sess2[0].videos[0].src ||
          eduSessions.sess3[0].videos[0].src
            ? true
            : false,
        hasVidAccessAll:
          eduSessions.sess1[0].videos[0].src &&
          eduSessions.sess2[0].videos[0].src &&
          eduSessions.sess3[0].videos[0].src
            ? true
            : false,
      });
    }
  }, [eduSessions]);

  return (
    <EduContext.Provider value={{ sessionData: eduSess }}>
      {children}
    </EduContext.Provider>
  );
};

const EduSessionConsumer = EduContext.Consumer;

const useEduSessions = () => useContext(EduContext);

export { EduSessionProvider, useEduSessions, EduSessionConsumer };

export default EduContext;
