import filterConfigReducer, { TFilterConfigInitialState } from 'redux/slices/filterConfigSlice';
import filterStateReducer, { TFilterStateInitialState } from 'redux/slices/filterStateSlice';

const rootReducer = {
  filterConfig: filterConfigReducer,
  filterState: filterStateReducer,
};

export interface IReduxState {
  filterConfig: TFilterConfigInitialState,
  filterState: TFilterStateInitialState,
};

export default rootReducer;
